import App from 'App';
import { instanceInterceptors } from 'api';
import ErrorBoundaryComponent from 'components/ErrorBoundary/ErrorBoundaryComponent';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <Provider store={store}>
      <ErrorBoundaryComponent>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </ErrorBoundaryComponent>
    </Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
instanceInterceptors(store);
